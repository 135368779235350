<template>
  <div style="margin-left: -70px;margin-top:20px;border: 1px solid #000;">
    <div style="margin-bottom: 10px;margin-top: 10px;display: flex;justify-content: space-between;">
      <div style="position:relative;padding-left: 20px;">
        <span>按地址搜索:</span>
        <!--<el-input id="keyword" name="keyword" v-model="address" style="width: 400px;"></el-input>-->
        <input id="keyword" v-model="address" style="width: 400px;margin-left: 5px;" placeholder="请输入地址" autocomplete="off" type="text">
        <div id="tipbox" style="border: 1px solid #000;position: absolute;top:60px;z-index: 1024;background: #fff;"></div>
      </div>
      <!--<div>-->
      <!--  <span>点击获取经纬度</span>-->
      <!--  <el-input v-model="jwd" style="width: 200px;" disabled></el-input>-->
      <!--</div>-->
    </div>
    <div id="container"></div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    return {
      map: null,
      geoCoder: null,
      AMap: null,
      markersPosition: [],
      markers: [],
      jwd: '',
      address: '',
      autoCompleteComponent: null,
      placeSearchComponent: null
    }
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: '98a14dc99a15cc533b911e235c44af20',
        plugins: ['AMap.Geocoder','AMap.Autocomplete','AMap.PlaceSearch']
      }).then((AMap) => {
        this.map = new AMap.Map("container",{  // 设置地图容器id
          viewMode:"3D",    // 是否为3D地图模式
          zoom:10,           // 初始化地图级别
          center:[120.14,30.25], // 初始化地图中心点位置
          keyboardEnable: false
        });
        this.AMap = AMap
        this.geoCoder = new AMap.Geocoder()
        // 搜索
        let that = this
        AMap.plugin(['AMap.Autocomplete','AMap.PlaceSearch'], function () {
          let autoOptions = {
            input: 'keyword',//使用联想输入的input的id
            datatype: 'poi',
            output: 'tipbox'
          };
          let autocomplete = new AMap.Autocomplete(autoOptions)
          let placeSearch = new AMap.PlaceSearch({
            city: '全国',
            map: that.map
          })
          console.log(placeSearch)
          AMap.event.addListener(autocomplete, 'select', (e) => {
            // console.log('监听事件', e);
            that.seach(e)
          })
        })
        this.handlerMapClick()
      }).catch(e=>{
        console.log(e, '<<<<<<<<<地图创建失败');
      })
    },
    // 搜索
    seach(e) {
      // console.log(e, '搜索事件<<<<<<')
      this.markersPosition = [e.poi.location.lng, e.poi.location.lat]
      this.jwd = this.markersPosition.toString()
      this.$emit('la', this.markersPosition)
      this.map.setCenter(this.markersPosition)
      this.removeMarker()
      this.setMapMarker()
      let that = this
      this.geoCoder.getAddress(this.markersPosition, function (status,result){
        if (status === 'complete' && result.regeocode) {
          that.address = result.regeocode.formattedAddress
          that.$emit('address', that.address)
        }
      })
    },
    handlerMapClick() {
      this.map.on('click',(e) => {
        // 点击坐标
        this.markersPosition = [e.lnglat.lng, e.lnglat.lat]
        this.jwd = this.markersPosition.toString()
        this.$emit('la', this.markersPosition)
        this.removeMarker()
        this.setMapMarker()
        let that = this
        this.geoCoder.getAddress(this.markersPosition, function (status,result){
          if (status === 'complete' && result.regeocode) {
            that.address = result.regeocode.formattedAddress
            that.$emit('address', that.address)
          }
        })
      })
    },
    setMapMarker() {
      let marker = new this.AMap.Marker({
        map: this.map,
        position: this.markersPosition
      })
      this.markers.push(marker)
    },
    removeMarker() {
      if (this.markers) {
        this.map.remove(this.markers)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#container{
  padding: 0;
  margin: 0;
  width: 100%;
  height: 600px;
}
#keyword{
  //background-color: #F5F7FA;
  border-color: #E4E7ED;
  //color: #C0C4CC;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  border: none;
  border: 1px solid #C0C4CC;
  border-radius: 4px;
}
</style>
