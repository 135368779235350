<template>
  <div class="card_index">
    <div class="index_top">
      <div class="index_top_left">
        <img :src="'http://yy-camping-hz.oss-cn-hangzhou.aliyuncs.com' + info.imgUrl" alt="">
      </div>
      <div class="index_top_center">
        <p style="text-align: center">{{ info.name }}</p>
        <p class="index_top_center_time">创建时间: {{ info.createTime }}</p>
      </div>
      <div class="index_top_right">
        <el-switch
            v-model="info.isshow"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="0"
            @change="switchChange"
        >
        </el-switch>
      </div>
    </div>
    <div class="index_center">
      <div style="margin-bottom: 18px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">
        <span>地址:</span>
        <el-tooltip class="item" effect="dark" :content="info.address" placement="top-start">
          <span style="white-space:nowrap;overflow:hidden;text-overflow:ellipsis;">{{ info.address }}</span>
        </el-tooltip>
        <!--<span>{{ info.address }}</span>-->
      </div>
      <div>
        <span>电话:</span>
        <span>{{ info.phone }}</span>
      </div>
    </div>
    <div class="index_bottom">
      <div class="index_bottom_left">
        <!--<span style="cursor: pointer;">店铺详情</span>-->
        <span style="margin-left: 10px;cursor: pointer;" @click="gateTo">进入后台</span>
        <span style="margin-left: 10px;cursor:pointer;" @click="czps">重置密码</span>
        <span style="margin-left: 10px;cursor:pointer;" @click="edit">编辑</span>
        <span style="margin-left: 10px;cursor:pointer;" @click="look">查看详情</span>
      </div>
      <div>
        <span style="cursor: pointer;" @click="deleteCa">删除</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name:{
      type: String,
      default: '露呗'
    },
    createTime: {
      type: String,
      default: '2022-04-01'
    },
    address: {
      type: String,
      default: '杭州市拱墅区'
    },
    phone: {
      type: String,
      default: '13012345678'
    },
    SwithchValue: {
      type: Boolean,
      default: false
    },
    info: {
      type: Object,
      default: function () {
        return {}
      }
    }
  },
  methods: {
    switchChange(e) {
      console.log(e)
      console.log(this.info)
      this.$emit('switch', e, this.info)
    },
    // 进入后台
    gateTo(){
      // localStorage.removeItem('layuiAdmin')
      // // window.open('http://yyadmin.yunyouzhihui.com/start/index.html#/user/loginLbPlatForm', '_black')
      // window.open(`${this.$BASEURL}/start/index.html#/user/loginLbPlatForm`, '_black')
      // console.log(window.parent);
      const data = {
        userId: this.info.userId,
        links: this.info.links
      }
      window.parent.postMessage(data,'*')
    },
    // 重置密码
    czps() {
      this.$emit('resetPswd', this.info.userId)
    },
    // 删除
    deleteCa() {
      this.$emit('deleteItem', this.info.pid)
    },
    // 编辑
    edit() {
      this.$emit('SetEmit', this.info)
    },
    // 查看详情
    look() {
      this.$emit('Look', this.info.pid)
    }
  }
}
</script>

<style lang="scss" scoped>
.card_index{
  width: 500px;
  border: 1px solid #348DF0;
  background: #F3F9FF;
  margin: 10px;
  border-radius: 4px;
  .index_top{
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 13px;
    .index_top_left{
      width: 66px;
      height: 66px;
      background: #00A0E9;
      border-radius: 50%;
      img{
        width: 66px;
        height: 66px;
        background: #00A0E9;
        border-radius: 50%;
      }
    }
    .index_top_center{
      margin-left: 17px;
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      .index_top_center_time{
        font-size: 18px;
        margin-top: 17px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
    }
    .index_top_right{
      margin-left: 25px;
      display: flex;
      align-items: center;
    }
  }
  .index_center{
    padding-left: 20px;
    padding-top: 36px;
    padding-bottom: 25px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #666666;
    border-bottom: 1px dashed #2F69A9;
  }
  .index_bottom{
    padding: 23px 13px 22px 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #348DF0;
    .index_bottom_left{
      flex: 1.2;
      display: flex;
    }
  }
}
</style>
