import request from "../request/request";

export function list() {
    return request({
        url: '/yylb/platformMgr/listAll',
        // url: '/yylb/bk/platformListAll',
        method: 'GET'
    })
}

// 上架
export function rackUpAndDown(pid, state) {
    return request({
        url: `/yylb/platformMgr/rackUpAndDown?pid=${pid}&state=${state}`,
        // url: '/yylb/bk/rackUpAndDown',
        method: 'GET'
    })
}

// 重置密码
export function UpdatePassWord(data) {
    return request({
        url:`/UpdatePassWord?userId=${data}`,
        // url: `/yylb/bk/UpdatePassWord?userId=${data}`,
        method: 'POST'
    })
}
// 删除
export function deleteUser(data) {
    return request({
        url:`/yylb/platformMgr/delete?id=${data}`,
        method: 'POST'
    })
}

// 获取省市区
export function getArea(data) {
    return request({
        url: `/yylb/areaMgr/queryList`,
        method: 'POST',
        data
    })
}

// 新增商家
export function platformMgrAdd(data) {
    return request({
        url: '/yylb/platformMgr/Add',
        method: 'POST',
        data
    })
}

// 获取商家详情
export function getInfo(pid) {
    return request({
        url: `/yylb/platformMgr/getInfo?pid=${pid}`,
        method: 'get'
    })
}

// 编辑商家信息
export function platformMgrUpdate(data) {
    return request({
        url: '/yylb/platformMgr/update',
        method: 'POST',
        data
    })
}
// 获取所有城市
export function GetAreaList(data) {
    return request({
        url: '/yylb/areaMgr/areaList',
        method: 'post',
        data
    })
}
// 上传封面
export function uploadNew(flie) {
    return request({
        url: '/common/uploadNew',
        method: 'POST',
        data: flie
    })
}
