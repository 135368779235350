<template>
  <div class="index">
    <div class="index_box">
      <div>
        <!--<el-button type="primary" @click="add">新建平台</el-button>-->
        <el-button type="primary" @click="addPl">新建</el-button>
      </div>
      <div class="index_list">
        <card v-for="(item, index) in list" :key="index" :info="item" @switch="siwtch" @resetPswd="resetPswd" @deleteItem="deleteItem" @SetEmit="editItem" @Look="lookInfo"></card>
      </div>
    </div>
    <!--<div v-if="TotalContent !== 0" style="text-align: right;">-->
    <!--  <el-pagination-->
    <!--      background-->
    <!--      layout="prev, pager, next"-->
    <!--      :total="100"-->
    <!--      :page-size="8"-->
    <!--      :current-page="current"-->
    <!--      @current-change="currentChange"-->
    <!--  >-->
    <!--  </el-pagination>-->
    <!--</div>-->
    <el-dialog title="新增" top="0" :visible.sync="addShow" width="50%" :modal="false" :before-close="handleClose">
      <div slot="title">
        新增
      </div>
      <el-form label-width="180px" label-position="center">
        <el-form-item label="名称" :rules="{ required: true, message: '请输入名称', trigger: 'blur' }">
          <el-input v-model="addName" class="add_input" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="LOGO" :rules="{ required: true, message: '请上传封面图', trigger: 'blur' }">
          <!--<div class="up_box" @click="upFile">-->
          <!--  <img v-if="addImgUrl" :src="addImgUrl" style="width: 100px;height: 100px;" alt="">-->
          <!--  <i v-else class="el-icon-upload" style="line-height: 100px;"></i>-->
          <!--</div>-->
          <el-upload
              class="avatar-uploader"
              :action="this.$BASEURL + '/common/uploadNew'"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
          >
            <img v-if="addImgUrl" :src="addImgUrl" class="avatar" alt="">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="地址" :rulse="{ required: true, message: '请选择地址', trigger: 'change' }">
          <el-cascader
              class="add_input"
              v-model="AddcityCode"
              :options="addOptions"
              :props="cascaderProps"
              @change="cityChange"
          />
        </el-form-item>
        <el-form-item label="办公地址" :rules="{ required: true, message: '请选择办公地址', trigger: 'blur' }">
          <el-input v-model="addAddress" class="add_input" type="textarea" placeholder="请选择办公地址"></el-input>
          <Map ref="map" @la="getLA" @address="getAddress"></Map>
        </el-form-item>
        <el-form-item label="联系电话" :rules="{ required: true, message: '请输入联系电话', trigger: 'blur' }">
          <el-input v-model="addPhone" class="add_input" placeholder="请输入联系电话"></el-input>
        </el-form-item>
        <el-form-item label="管理员账号" :rules="{ required: true, message: '请输入管理员账号', trigger: 'blur' }">
          <el-input v-model="addUser" class="add_input" placeholder="请输入管理员账号"></el-input>
        </el-form-item>
        <el-form-item label="APPID">
          <el-input v-model="addAPPID" class="add_input" placeholder="请输入小程序APPID"></el-input>
          <a href="https://jingyan.baidu.com/article/f54ae2fc24a1a45f93b84908.html" target="_blank" style="margin-left: 10px;">点击查看如何获取小程序APPID</a>
        </el-form-item>
        <el-form-item label="小程序秘钥AppSecret">
          <el-input v-model="secret" class="add_input" placeholder="请输入小程序秘钥"></el-input>
          <a href="https://jingyan.baidu.com/article/67508eb4e7c6eeddcb1ce40f.html" target="_blank" style="margin-left: 10px;">点击查看如何获取小程序秘钥AppSecret</a>
        </el-form-item>
        <el-form-item label="商户号秘钥">
          <el-input v-model="addAPPKEY" class="add_input" placeholder="请输入小程序秘钥"></el-input>
          <a href="https://zhidao.baidu.com/question/1247061806596045179.html" target="_blank" style="margin-left: 10px;">点击查看如何获取商户号秘钥</a>
        </el-form-item>
        <el-form-item label="商户号">
          <el-input v-model="addMCHID" class="add_input" placeholder="请输入小程序商户号"></el-input>
          <a href="https://zhidao.baidu.com/question/1247061806596045179.html" target="_blank" style="margin-left: 10px;">点击查看如何获取小程序商户号</a>
        </el-form-item>
        <el-form-item label="支付证书路径">
          <el-input v-model="addPayPath" class="add_input" placeholder="请输入支付证书路径"></el-input>
          <a href="https://kf.qq.com/faq/161222NneAJf161222U7fARv.html" target="_blank" style="margin-left: 10px;">点击查看如何获取支付证书路径</a>
        </el-form-item>
      </el-form>
      <div style="padding-left: 120px;">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="submitAdd">确认</el-button>
      </div>
    </el-dialog>
    <!--编辑-->
    <el-dialog title="编辑" width="50%" top="0" :visible.sync="editShow" :modal="false" :before-close="editClose">
      <el-form label-position="center" label-width="180px">
        <el-form-item label="名称" :rules="{ required: true }">
          <el-input v-model="editInfo.name" class="add_input"></el-input>
        </el-form-item>
        <el-form-item label="LOGO" :rules="{ required: true }">
          <el-upload
              class="avatar-uploader"
              :action="this.$BASEURL + '/common/uploadNew'"
              :show-file-list="false"
              :on-success="editupSuccss"
          >
            <img v-if="editInfo.imgUrl" :src="'http://yy-camping-hz.oss-cn-hangzhou.aliyuncs.com' + editInfo.imgUrl" class="avatar" alt="">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="地址" :rules="{ required: true }">
          <el-cascader
              v-model="editCode"
              :options="editCityList"
              class="add_input"
              :props="editProps"
              @change="editCodeChange"
          />
        </el-form-item>
        <el-form-item label="办公地址" :rules="{ required: true }">
          <el-input v-model="editInfo.address" class="add_input"></el-input>
          <EditMap ref="EditMap" @address="editaddress" @la="editla"></EditMap>
        </el-form-item>
        <el-form-item label="联系电话" :rules="{ required: true }">
          <el-input v-model="editInfo.phone" class="add_input"></el-input>
        </el-form-item>
        <!--<el-form-item label="管理员账号">-->
        <!--  <el-input v-model="editInfo.userName" class="add_input"></el-input>-->
        <!--</el-form-item>-->
        <el-form-item label="APPID">
          <el-input v-model="editInfo.appId" class="add_input"></el-input>
          <a href="https://jingyan.baidu.com/article/f54ae2fc24a1a45f93b84908.html" target="_blank" style="margin-left: 10px;">点击查看如何获取小程序APPID</a>
        </el-form-item>
        <el-form-item label="小程序秘钥AppSecret">
          <el-input v-model="editInfo.secret" class="add_input"></el-input>
          <a href="https://jingyan.baidu.com/article/67508eb4e7c6eeddcb1ce40f.html" target="_blank" style="margin-left: 10px;">点击查看如何获取小程序秘钥AppSecret</a>
        </el-form-item>
        <el-form-item label="商户号秘钥">
          <el-input v-model="editInfo.mchKey" class="add_input"></el-input>
          <a href="https://zhidao.baidu.com/question/1247061806596045179.html" target="_blank" style="margin-left: 10px;">点击查看如何获取商户号秘钥</a>
        </el-form-item>
        <el-form-item label="商户号">
          <el-input v-model="editInfo.mchId" class="add_input"></el-input>
          <a href="https://zhidao.baidu.com/question/1247061806596045179.html" target="_blank" style="margin-left: 10px;">点击查看如何获取小程序商户号</a>
        </el-form-item>
        <el-form-item label="支付证书路径">
          <el-input v-model="editInfo.certPath" class="add_input"></el-input>
          <a href="https://kf.qq.com/faq/161222NneAJf161222U7fARv.html" target="_blank" style="margin-left: 10px;">点击查看如何获取支付证书路径</a>
        </el-form-item>
      </el-form>
      <div style="padding-left: 120px;">
        <el-button @click="editClose">取消</el-button>
        <el-button type="primary" @click="eidtSave">保存</el-button>
      </div>
    </el-dialog>
    <!--查看详情-->
    <el-dialog title="详情" width="50%" :visible.sync="lookShow" :modal="false" :before-close="lookClose">
      <el-form label-width="180px" label-position="center">
        <el-form-item label="名称">
          <span>{{ lookDetail.name }}</span>
        </el-form-item>
        <el-form-item label="LOGO">
          <img :src="'http://yy-camping-hz.oss-cn-hangzhou.aliyuncs.com' + lookDetail.imgUrl" style="width: 100px;height: 100px;" alt="">
        </el-form-item>
        <el-form-item label="地址">
          <span>{{ lookDetail.provinceName }}/{{ lookDetail.cityName }}</span>
        </el-form-item>
        <el-form-item label="办公地址">
          <span>{{ lookDetail.address }}</span>
        </el-form-item>
        <el-form-item label="联系电话">
          <span>{{ lookDetail.phone }}</span>
        </el-form-item>
        <el-form-item label="APPID">
          <span>{{ lookDetail.appId }}</span>
        </el-form-item>
        <el-form-item label="小程序秘钥AppSecret">
          <span>{{ lookDetail.secret }}</span>
        </el-form-item>
        <el-form-item label="商户号秘钥">
          <span>{{ lookDetail.mchKey }}</span>
        </el-form-item>
        <el-form-item label="商户号">
          <span>{{ lookDetail.mchId }}</span>
        </el-form-item>
        <el-form-item label="支付证书路径">
          <span>{{ lookDetail.certPath }}</span>
        </el-form-item>
        <el-form-item label="管理员账号">
          <span>{{ lookDetail.userName }}</span>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--<input type="file" id="upload" style="display: none;" />-->
  </div>
</template>

<script>
import {list, rackUpAndDown, UpdatePassWord, deleteUser, getArea, platformMgrAdd, getInfo, GetAreaList, platformMgrUpdate} from "../../api/PlatformManager";
import Map from './module/map'
import EditMap from './module/editMap'
import card from "./module/card";
export default {
  components: {
    card,
    Map,
    EditMap
  },
  data() {
    return {
      list: [],
      current: 1,
      TotalContent: 0,
      addShow: false,
      addAddress: '',
      addOptions: [],
      AddcityCode: '',
      addName: '',
      addPhone: '',
      addUser: '',
      addLink: '',
      addAPPID: '',
      addAPPKEY: '',
      addMCHID: '',
      addPayPath: '',
      addImgUrl: '',
      secret: '',
      latitude: null, // 纬度
      longitude: null, // 经度
      cascaderProps: {
        checkStrictly: false,
        lazy: true,
        value: 'code',
        label: 'name',
        async lazyLoad(node,resolve){
          try {
            const { data, level } = node;
            const { code } = data
            if (level < 2) {
              const parms = new FormData()
              parms.append('parentid', code)
              const res = await getArea(parms)
              if (res.ResultCode === 0) {
                resolve(res.Data)
              }
            } else {
              resolve()
            }
          }catch (e) {
            console.log(e, '错误')
          }
        }
      },
      editProps: {
        checkStrictly: false,
        value: 'code',
        label: 'name'
      },
      // 编辑
      editShow: false,
      editInfo: {},
      editCode: '',
      editCityList: [],
      // 查看详情
      lookShow: false,
      lookDetail: {}
    }
  },
  mounted() {
    this.getList()
    this.getAreaList()
    this.cs1()
  },
  methods: {
    async cs1() {
      const parms = new FormData()
      parms.append('level', 1)
      const res = await getArea(parms)
      if (res.ResultCode === 0) {
        let s = res.Data
        // console.log(s, '省')
        // const parms1 = new FormData()
        // parms.append('level', 2)
        const parms = {
          page: 0
        }
        let all = []
        const city = await GetAreaList(parms)
        if (city.ResultCode === 0) {
          // console.log(city, '市')
          let cityArr = city.Data
          for (let i=0;i<s.length;i++) {
            all.push({
              code: s[i].code,
              createTime: s[i].createTime,
              id: s[i].id,
              name: s[i].name,
              children: []
            })
          }
          // console.log(all, 'all')
          for (let i =0;i<all.length;i++) {
            for(let j =0;j<cityArr.length;j++) {
              if (cityArr[j].parentid === all[i].code) {
                all[i].children.push(cityArr[j])
              }
            }
          }
          // console.log(all, 'zui')
          this.editCityList = all
        }
      }
    },
    async getList() {
      const res = await list()
      if (res.ResultCode === 0) {
        this.list = res.Data
      }
    },
    async getAreaList() {
      const parms = new FormData()
      parms.append('level', 1)
      const res = await getArea(parms)
      if (res.ResultCode === 0) {
        this.addOptions = res.Data
      }
    },
    // 重置密码
    resetPswd(id) {
      this.$alert('您确定要重置密码吗？确定重置后密码将恢复为初始密码888888','提示',{
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        callback: async () => {
          const res = await UpdatePassWord(id)
          if (res.ResultCode === 0) {
            this.$message({
              type: 'success',
              message: '重置密码成功'
            })
          }
        }
      })
    },
    // 平台上下架
    async siwtch(val, info) {
      // [0.下架|1.上架]
      const state = val
      if (state === 1) {
        if (!info.appId || !info.certPath || !info.mchId || !info.mchKey || !info.secret) {
          this.$message.error('请先完善小程序基础信息后上架')
          info.isshow = 0
        } else {
          const res = await rackUpAndDown(info.pid, state)
          if (res.ResultCode === 0) {
            this.getList()
          }
        }
      } else {
        const res = await rackUpAndDown(info.pid, state)
        if (res.ResultCode === 0) {
          this.getList()
        }
      }
    },
    // 删除
    deleteItem(id) {
      this.$alert('确认要删除平台吗?','提示',{
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        callback: async () => {
          const res = await deleteUser(id)
          if (res.ResultCode === 0) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          }
        }
      })
    },
    add() {
      // window.open('http://testapi.yunyouzhihui.com/start/index.html#/yy/yy_platform/add', '_black')
      // window.open('http://yyadmin.yunyouzhihui.com/start/index.html#/yy/yy_platform/add/index.html', '_black')
      window.open(`${this.$BASEURL}/start/index.html#/yy/yy_platform/add`, '_black')
      // this.addShow = true
    },
    addPl() {
      this.addShow = true
    },
    handleClose() {
      this.addShow = false
      this.resetAddParms()
    },
    // 获取经纬度
    getLA(val) {
      console.log(val, '获取经纬度')
      this.longitude = val[0]
      this.latitude = val[1]
    },
    // 地址
    getAddress(val) {
      this.addAddress = val
    },
    // 重置新增需要的参数
    resetAddParms() {
      this.addName = ''
      this.addPhone = ''
      this.addUser = ''
      this.addLink = ''
      this.addAPPID = ''
      this.addAPPKEY = ''
      this.addMCHID = ''
      this.addImgUrl = ''
      this.addAddress = ''
      this.AddcityCode = ''
      this.addPayPath = ''
      this.secret = ''
      this.$refs.map.address = ''
    },
    async submitAdd() {
      if (!this.addName) {
        this.$message.error('请输入名称')
        return
      }
      if (!this.addImgUrl) {
        this.$message.error('请上传封面图')
        return
      }
      if (!this.AddcityCode) {
        this.$message.error('请选择地址')
        return
      }
      if (!this.addAddress) {
        this.$message.error('请选择办公地址')
        return
      }
      if (!this.addPhone) {
        this.$message.error('请输入联系电话')
        return
      }
      if (!this.addUser) {
        this.$message.error('请输入管理员账号')
        return
      }
      // if (!this.secret) {
      //   this.$message.error('请输入小程序秘钥')
      //   return
      // }
      // if (!this.addAPPID) {
      //   this.$message.error('请输入小程序APPID')
      //   return
      // }
      // if (!this.addAPPKEY) {
      //   this.$message.error('请输入小程序KEY')
      //   return
      // }
      // if (!this.addMCHID) {
      //   this.$message.error('请输入小程序商户号')
      //   return
      // }
      // if (!this.addPayPath) {
      //   this.$message.error('请输入支付证书路径')
      //   return
      // }
      console.log('调起接口', this.AddcityCode)
      const parms = {
        address: this.addAddress, // 办公地址
        appId: this.addAPPID, // 小程序APPID
        certPath: this.addPayPath, // 支付证书路径
        mchId: this.addMCHID, // 小程序商户号
        mchKey: this.addAPPKEY, // 小程序商户号秘钥
        name: this.addName, // 平台名称
        phone: this.addPhone, // 联系电话
        secret: this.secret, // 小程序秘钥
        provinceCode: this.AddcityCode[0], // 省编码
        cityCode: this.AddcityCode[1], // 市编码
        longitude: this.longitude, // 经度
        latitude: this.latitude, // 纬度
        userId: this.addUser, // 管理员账号
        imgUrl: this.addImgUrl.split('com')[1] // 封面图
      }
      // console.log(parms)
      const res = await platformMgrAdd(parms)
      // console.log(res)
      if (res.ResultCode === 0) {
        this.$message({
          type: 'success',
          message: '添加成功'
        })
        this.addShow = false
        this.resetAddParms()
        this.getList()
      }
    },
    cityChange(e) {
      console.log(e)
    },
    async editItem(item) {
      // let obj = {}
      // obj = { ...obj,...item }
      // this.editInfo = obj
      // this.editCode = [obj.provinceCode, obj.cityCode]
      // console.log(this.editCode, '8978')
      // this.editShow = true
      const res = await getInfo(item.pid)
      console.log(res)
      if (res.ResultCode === 0) {
        this.editCode = [res.Data.provinceCode, res.Data.cityCode]
        console.log(this.editCode, 'editCode')
        this.editInfo = res.Data
        this.editShow = true
      }
    },
    editClose() {
      this.editInfo = {}
      this.editCode = []
      this.$refs.EditMap.address = ''
      this.editShow = false
    },
    editCodeChange(e) {
      console.log(e)
    },
    editaddress(val) {
      // console.log(val, '编辑获取地址')
      this.editInfo.address = val
    },
    editla(val) {
      // console.log(val, '编辑获取经纬度')
      this.editInfo.longitude = val[0]
      this.editInfo.latitude = val[1]
    },
    async eidtSave() {
     if (!this.editInfo.name) {
       this.$message.error('请输入名称')
       return
     }
     if (!this.editInfo.imgUrl) {
       this.$message.error('请上传封面图')
       return
     }
     if (!this.editInfo.cityCode) {
       this.$message.error('请选择地址')
       return
     }
     if (!this.editInfo.address) {
       this.$message.error('请选择办公地址')
       return
     }
     if (!this.editInfo.phone) {
       this.$message.error('请输入联系方式')
       return
     }
     if (!this.editInfo.userName) {
       this.$message.error('请输入管理员账号')
       return
     }
     // if (!this.editInfo.appId) {
     //   this.$message.error('请输入小程序APPID')
     //   return
     // }
     // if (!this.editInfo.secret) {
     //   this.$message.error('请输入小程序秘钥')
     //   return
     // }
     // if (!this.editInfo.mchId) {
     //   this.$message.error('请输入小程序商户号')
     //   return
     // }
     // if (!this.editInfo.mchKey) {
     //   this.$message.error('请输入小程序商户号秘钥')
     //   return
     // }
     // if (!this.editInfo.certPath) {
     //   this.$message.error('请输入小程序支付证书路径')
     //   return
     // }
     const parms = {
       address: this.editInfo.address, // 办公地址
       appId: this.editInfo.appId, // 小程序id
       certPath: this.editInfo.certPath, // 小程序支付证书路径
       cityCode: this.editCode[1], // 城市编码
       latitude: this.editInfo.latitude, // 纬度
       longitude: this.editInfo.longitude, // 经度
       mchId: this.editInfo.mchId, // 小程序商户号
       mchKey: this.editInfo.mchKey, // 小程序商户号秘钥
       name: this.editInfo.name, // 平台名称
       phone: this.editInfo.phone, // 联系方式
       provinceCode: this.editCode[0], // 省编码
       secret: this.editInfo.secret, // 小程序秘钥
       // userId: this.editInfo.userName,
       pid: this.editInfo.pid,
       imgUrl: this.editInfo.imgUrl
     }
     console.log(parms)
      const res = await platformMgrUpdate(parms)
      if (res.ResultCode === 0) {
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.$refs.EditMap.address = ''
        console.log(this.$refs.EditMap.address, 'this.$refs.EditMap.address')
        this.editInfo = {}
        this.editShow = false
        this.getList()
      }
    },
    async lookInfo(pid) {
      console.log(pid)
      const res = await getInfo(pid)
      console.log(res, '查看详情')
      if (res.ResultCode === 0) {
        this.lookShow = true
        this.lookDetail = res.Data
      }
    },
    lookClose() {
      this.lookShow = false
      this.lookDetail = {}
    },
    // 上传文件
    upFile() {
      // let that = this
      // const input = document.querySelector("#upload");
      // input.click()
      // input.addEventListener('change', async function (e) {
      //   console.log(e)
      //   console.log(input.files)
      //   let files = input.files[0]
      //   console.log(files, '图片')
      //   that.addImgUrl = window.URL.createObjectURL(files)
      //   console.log(that.addImgUrl)
      // })
    },
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      if (res.ResultCode === 0) {
        this.addImgUrl = res.url
      }
    },
    editupSuccss(res, file) {
      console.log(file)
      if (res.ResultCode === 0) {
        this.editInfo.imgUrl = res.fileName
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.index{
  padding: 30px;
  .index_box{
    background: #fff;
    .index_list{
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
      margin-top: 33px;
    }
  }
}
.add_input{
  width: 300px;
}
.up_box{
  width: 100px;
  height: 100px;
  border: 1px solid #E4E7ED;
  text-align: center;
  cursor: pointer;
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
